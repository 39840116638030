import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import persist from 'pinia-plugin-persistedstate'

import App from './App.vue'
import router from '@/router'
import { i18n } from '@/i18n'
import 'vant/lib/index.css';
import {
    Icon,
    Checkbox,
    FloatingBubble,
    Loading,
    Overlay,
    Row,
    Col,
    NavBar,
    Tag,
    DatePicker,
    Tab,
    Tabs,
    Field,
    Dialog,
    RadioGroup,
    Radio,
    Popup,
    Picker,
    Button,
    Space,
    Divider,
    Image,
    Toast,
} from 'vant';

import '@vant/touch-emulator';
const app = createApp(App)
app.use(createPinia().use(persist))
app.use(router)
app.use(i18n);
app.use(Icon)
.use(FloatingBubble)
.use(Loading)
.use(Overlay)
.use(Row)
.use(Col)
.use(Checkbox)
.use(NavBar)
.use(Tag)
.use(DatePicker)
.use(Tab)
.use(Tabs)
.use(Field)
.use(Dialog)
.use(RadioGroup)
.use(Radio)
.use(Popup)
.use(Picker)
.use(Button)
.use(Space)
.use(Divider)
.use(Image)
.use(Toast)

app.mount('#app')
