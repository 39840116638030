<template>
  <!-- iPad 端不需要局限H5適配 -->
  <div :class="{'pc_protect_h5': systemStore.isPc && !systemStore?.isiPad }">
    <div class="loading" v-if="systemStore.loading">
      <div class="loading-box">
        <van-loading class="cus-page-loading" size="24px" color="#DE0080" vertical>LOADING...</van-loading>
      </div>
    </div>
    <router-view v-slot="{ Component }">
      <keep-alive include="couponMy,couponReceive">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script setup lang="ts">
  import { onUnmounted } from "vue";
  import { RouterView } from 'vue-router'
  import { Loading } from 'vant';
  import { useSystemStore } from '@/stores/system'
  import { useRouter } from 'vue-router';
  const router = useRouter();
  const systemStore = useSystemStore()
  systemStore.setiPad(false);
  systemStore.setLang("");
  // 卸载页面后清除缓存
  onUnmounted(() => {
    systemStore.reset();
  })
  
</script>

<style scoped lang="scss">

  * {
    color: #333;
    font-family: $PFSCM;
  }
  
  .loading {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    z-index: 2000;
    background: rgba(0, 0, 0, .3);

    .loading-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  header {
    line-height: 1.5;
    max-height: 100vh;
  }

  .pc_protect_h5 {
    width: 375px;
    height: 725px;
    /* margin: 30px auto;
    position: relative; */
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #E2E2E2;
    box-sizing: border-box;

    .loading {
      width: 100%;
      height: 100%;
    }

    :deep(::-webkit-scrollbar) {
      width: 7px;
      height: 7px;
    }
    :deep(::-webkit-scrollbar-thumb) {
      background-color: #ddd;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
      background-clip: padding-box;
    }

    :deep(::-webkit-scrollbar-track-piece) {
      background-color: #f8f8f8;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }

    .ignore- {
      width: 100% !important;
      height: 100% !important;
      overflow-y: auto !important;
    }
  }
</style>