import { createI18n } from 'vue-i18n'
import zhLocale from './config/zh'
import enLocale from './config/en'
import zh_hk from './config/zh-hk'

import { Locale } from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhHK from 'vant/lib/locale/lang/zh-HK'

const messages = {
  en: {
    ...enUS,
    ...enLocale
  },
  zh: {
    ...zhCN,
    ...zhLocale
  },
  'zh-hk': {
    ...zh_hk,
    // ...enLocale,
    ...zhHK
  }
}
// const language = (navigator.language || 'en').toLocaleLowerCase() // 这是获取浏览器的语言
export const i18n = createI18n({
  legacy: false,
  locale: 'zh-hk',
  // locale: localStorage.getItem('lang') || language.split('-')[0] || 'zh-hk', // 首先从缓存里拿，没有的话就用浏览器语言，
  globalInjection: true,
  fallbackLocale: 'zh-hk', // 设置备用语言
  messages, 
})
 
//翻译
export function translate(text,val) {
  if(!text) return
  if(!i18n.global.t(text)) {
    return val
  }
  return i18n.global.t(text)
}

// 切换vant语言
export function vantLocales(lang) {
  if (lang === 'en') {
    Locale.use('en-US', enUS)
  } else if (lang === 'zh') {
    Locale.use('zh-CN', zhCN)
  } else if (lang === 'zh-hk') {
    Locale.use('zh-HK', zhHK)
  }
}

