
    var hmID = import.meta.env.VITE_HM_ID;
    if (hmID) {
      var _hmt = _hmt || [];
      (function() {
        var hm = document.createElement("script");
        hm.src = `https://hm.baidu.com/hm.js?${hmID}`;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
    }
  