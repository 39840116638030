import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'


const router = createRouter({
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: '/member/home',
    },
    {
      path: '/backDoor',
      name: 'list',
      component: () => import('@/views/list.vue')
    },
    {
      path: '/member/home',
      name: 'memberMergeHome',
      component: () => import('@/views/member/index.vue')
    },
    {
      path: '/member/list',
      name: 'memberMerge',
      component: () => import('@/views/member/merge.vue')
    },
    {
      path: '/member/result',
      name: 'memberMergeResult',
      component: () => import('@/views/member/result.vue')
    },
    {
      path: '/member/privacy',
      name: 'memberMergePrivacy',
      component: () => import('@/views/member/privacy.vue')
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('@/views/order/index.vue')
    },
    {
      path: '/integral',
      name: 'integral',
      component: () => import('@/views/point/index.vue')
    },
    {
      path: '/coupon-my',
      name: 'couponMy',
      meta: {
        keepAlive: true
      },
      component: () => import('@/views/coupon/coupon-my.vue')
    },
    {
      path: '/coupon-receive',
      name: 'couponReceive',
      meta: {
        keepAlive: true
      },
      component: () => import('@/views/coupon/coupon-receive.vue')
    },
    {
      path: '/coupon-detail',
      name: 'coupon-detail',
      component: () => import('@/views/coupon/coupon-detail.vue')
    },
    
    {
      path: '/order-exchange',
      name: 'order-exchange',
      component: () => import('@/views/order-exchange/index.vue')
    },
    {
      path: '/order-exchange/phone',
      name: 'order-exchange-phone',
      component: () => import('@/views/order-exchange/phone.vue')
    },
    {
      path: '/order-exchange/exchange',
      name: 'order-exchange-exchange',
      component: () => import('@/views/order-exchange/exchange.vue')
    },
    {
      path: '/order-exchange/privacy',
      name: 'order-exchange-privacy',
      component: () => import('@/views/order-exchange/privacy.vue')
    },
    {
      path: '/lottery-home',
      name: 'lottery-home',
      meta: {
        title: '首页',
      },
      component: () => import('@/views/lottery/home.vue')
    },
    {
      path: '/lottery-prize',
      name: 'lottery-prize',
      meta: {
        title: '我的奖品',
      },
      component: () => import('@/views/lottery/myPrize.vue')
    },
    {
      path: '/lottery-detail/:id',
      name: 'lottery-detail',
      meta: {
        title: '抽奖详情',
      },
      component: () => import('@/views/lottery/details.vue')
    },
    {
      path: '/ipad-login',
      name: 'ipadLogin',
      meta: {
        title: 'ipadLogin',
      },
      component: () => import('@/views/ipad/crm/login/index.vue'),
    },
    {
      path: '/ipad',
      name: 'ipad',
      redirect: '/ipad/list',
      meta: {
        title: 'ipadHome',
      },
      component: () => import('@/views/ipad/crm/index/index.vue'),
      children: [
        {
          path: 'list',
          name: 'ipadCRMList',
          meta: {
            title: 'list',
            keepAlive: true,
          },
          component: () => import('@/views/ipad/crm/list/index.vue'),
        },
        {
          path: 'detail',
          name: 'ipadCRMDetail',
          meta: {
            title: 'detail',
          },
          component: () => import('@/views/ipad/crm/list/detail.vue'),
        },
        {
          path: 'order',
          name: 'ipadCRMOrder',
          meta: {
            title: 'order',
          },
          component: () => import('@/views/ipad/crm/list/order.vue'),
        },
        {
          path: 'register',
          name: 'ipadCRMRegister',
          meta: {
            title: 'register',
          },
          component: () => import('@/views/ipad/crm/register/index.vue'),
        },
        {
          path: 'social-media',
          name: 'ipadCRMRsocialMedia',
          meta: {
            keepAlive: true,
            title: 'Social media',
          },
          component: () => import('@/views/ipad/crm/socialMedia/index.vue'),
        },
        {
          path: 'media-detail',
          name: 'ipadCRMRMediaDetail',
          meta: {
            title: 'Member Detail',
          },
          component: () => import('@/views/ipad/crm/socialMedia/detail.vue'),
        },
      ],
    },
    {
      path: '/eForm/home',
      component: () => import('@/views/eForm/index.vue')
    },
    {
      path: '/eForm/edit',
      component: () => import('@/views/eForm/edit.vue')
    },
    {
      path: '/404',
      component: () => import('@/views/404.vue')
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('@/views/404.vue')
    }
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import('../views/AboutView.vue')
    // }
  ]
})

export default router
