// en.js
export default {
  lang: {
    // 会员码页面
    home: '首頁',
    normalMember: '普通會員',
    vipMember: 'VIP會員',
    superVip: 'Super VIP會員',
    deluxeClub: 'Deluxe Club會員',
    earnedPoints: '所得積分',
    membershipCode: '會員碼',
    perpetual: '永久',
    accumulatedConsumption: '累計消費',
    accumulatedConsumption2: '累計再消費',
    todaySpending: '或單次消費',
    upgradeLevel: '升級至',
    extend: '延續',
    validityPeriod: '有效期',
    currentConsumption: '當前消費',
    backBtn: "返回",
    grading: "已符合續會一年資格",
    upgrade: "升級",
    privacyTitle: "合併協議細則",
    consumetips: "詳情請參閱消費紀錄",
    RMB: "元",
    notFind1: "抱歉！ 頁面遺失啦~",
    notFind2: "建議退出(我的)，重新進入",
    // 合并会员
    mergePage: {
      menu: "合併帳戶",
      autoMerge: "其他曾登記帳戶",
      account: "卡號",
      name: "名稱",
      sex: "性别",
      mergeBtn: "合併",
      cancelBtn: "不合併",
      find: "請選取合併帳戶登記之生日資訊以作核實",
      storeOffline: "港澳門店",
      storeOnline: "港澳網店",
      applet: "海淘小程序",
      homeBtn: "帳戶合併",
      tips: "發現其他SASA帳號，可以進行合併",
      birthday: "生日",
      year: "年",
      month: "月",
      day: "日",
      afterTitle: "合併後",
      level: "等級",
      points: "可用積分",
      privacy: "《會員帳戶合併》",
      privacy1: "我已閱讀及同意",
      privacy2: "條款。",
      privacy3: "我已閱讀及同意莎莎的私隱政策。",
      privacy4: "我同意莎莎使用我的個人資料進行直接促銷。",
      confirm: "確定合併",
      waring1: "請注意，帳戶合併後不可取消。",
      waring2: "擊「同意」按鈕，預設您個人資訊可在SASA集團旗下的所有平臺進行共用，共用內容包括但不限於：個人資訊、積分、會員等級、訂單不等。",
      mergeSuccess: "合併成功",
      privacyMarks: "閣下曾登記超過1個莎莎會員帳戶（以登記手機號碼為準），請進行會員帳戶合併，享受更好的購物體驗。",
      privacyTitle: "《會員帳戶合併》條款及細則",
      privacy_1: "會員帳戶合併後，莎莎會把您的優惠券及積分合併，閣下的會員權益不會受到影響。",
      privacy_2: "會員帳戶合併後，閣下可以使用已合併的會員帳戶於門市及網店進行購物同時賺取積分。",
      privacy_3: "帳戶一經整合無法取消。如有查詢，請聯絡莎莎客戶服務熱線：(852) 2505 5023",
      monthRange: "3個月內",
      yearRange: "1年內",
      expiresOn: "有效期",
      errorTitle: "生日資訊錯誤",
      error1: "很抱歉，您填寫的",
      error2: "生日資訊與系統記錄不符，無法合併帳戶，請修改生日資訊。如未能成功合併請聯絡顧客服務熱線：(852) 2505 5023",
      gradingText1: "一年內累計消費",
      gradingText2: "可延續貴賓會籍多一年：",
      gradingText3: "你仍需消費",
      gradingText4: "才可續會",
    },

    // 积分页面
    myPoints: "我的積分",
    pointsFlow: '積分流水',
    availableIntegral: '積分結餘',
    pointDetail: '積分明細',
    ofwhich: '其中',
    points: '積分',
    will: '將於',
    expired: '過期',

    // 历史优惠券
    historicalCoupon: '歷史優惠券',
    all: '全部',
    used: '已換領',
    alreadyExpired: '已過期',

    // 购物记录
    orderLists: '購物記錄',
    originOrderCode: '訂單號碼',
    actualPayment: '實際金額',
    orderGoodsName: '商品名稱',
    pleaseEnterGoodsName: '請輸入商品名稱',
    orderType: '訂單類型',
    order: '購物單',
    specialOrder: '正負單',
    refundOrder: '退款單',
    orderDate: '訂單日期',
    nearlyAMonth: '近1個月',
    nearlyThreeMonths: '近3個月',
    nearlySixMonths: '近6個月',
    startDate: '開始時間',
    endDate: '結束時間',
    orderSource: '訂單來源',

    // 优惠券
    myCoupon: '我的優惠券',
    couponDetail: '優惠券詳情',
    receiveCoupon: '優惠券詳情',
    couponClaim: '領券中心',
    couponCode: '券Code',
    please: '請於',
    beforeUse: '前使用',
    usedFor: '適用於',
    useInstructions: '使用說明', 

    //按钮 
    ok: '確認',
    cancel: '取消',
    close: '關閉',
    reset: '重 置',
    query: '查 詢',
    confirmedUse: '立即使用',
    claim: '立即領取',
    distribute: '系統處理中',
    copy: "複製",
    finish: "完成",
    mergeFinish: "合併完成",
    agree: "同意",
    writeOff: "核銷",
    modify: "修改",

    // 提示
    copySuccess: '複製成功',
    copyFail: '複製失敗',
    noMore: '沒有更多了',
    confirmUseMsg: '確認使用後，優惠券不可退回，請確認是否現在使用？',
    useSuccessfully: '使用成功！',
    receiveSuccess: '領取成功！',
    editSuccess: '修改成功！',

    // 标题
    tip: '提示',
    filter: '篩選',
    selectStartDate: '選擇開始日期',
    selectEndDate: '選擇結束日期',
    chinaOnline: '海淘小程序',
    chinaOffline: '大陆分店',
    hongkongOnline: '港澳網店',
    hongKongOffline: '港澳門市',
    usePlatform: '選擇優惠券可用的平台',

    // 沒用的
    coupons: '優惠券',
    couponHistory: '歷史券',


    // 抽奖
    lottery: '抽獎',
    prizeDetails: '獎品詳情',
    lotteryStart: '開始抽獎',
    lotteryNum: {
      first: '您現在有',
      center: '次',
      last: '抽獎機會'
    },
    lotteryTipOne: '所有抽獎機會一次性消耗',
    lotteryTipTwo: '抽獎機會當天有效', 
    redemptionInfo: '抽獎資料​',
    winning: '恭喜！您抽中了',
    participation: '謝謝參與',
    phoneNumber: '電話號碼',
    orderNo: '發票號碼',
    actualPrice: '付款金額',
    exchangeDate: '兌換日期',
    myPrize: '我的獎品',
    prizeClaim: '現在換領',
    prizePlease: '請',
    pickItUpBy: '號前換領',
    receiveTime: '換領時間',
    expirationDate: '換領有效期',
    view: '已換領',
    real: '實物', 
    backHome: '返回首頁',
    congratulations: '恭喜您獲得了',
    selectStore: '選擇門店',
    pleaseSelectStore: '請選擇門店',
    selectRegionMsg: '請先選擇區域',
    before: '前',
    storeName: '門店名稱',
    storeAddress: '門店地址',
    prizeNo: '獎品單號',
    confirmClaim: '確定已換領',
    giftBoxLottery: '驚喜盒子抽獎',
    joinLottery: '參加抽獎',
    confirmMsg: '一經確認則默認已領獎，請確保已換領後再點擊確認',
    couponCode: '優惠券碼',
  },
  exchange: {
    placeholder: '請輸入',
    phonePlaceholder: '請輸入手機號碼',
    phoneError: '手機號碼格式不正確',
    areaCode: '地區代碼',
    phone: '手機號碼',
    submit: '提交',
    myPrize: '我的獎品',
    activityRule: '獎品詳情',
    joinActivity: '立即參加',
    exchangeLottery: '兌換抽獎機會',
    ticketInfo: '請提供莎莎港澳分店發票/莎莎網訂單信息',
    pleaseProvideExchangeOrder: '請提供兌換訂單',
    chooseOne: '(2選1)',
    marketTicket: '莎莎港澳分店發票',
    onlineTicket: '莎莎網訂單​',
    orderDate: '發票/訂單日期',
    defaultToday: '(默認當天)',
    invoiceImageTip: '【購物車編號TG後的12位數字】',
    orderImageTip: '【發票號碼樣本】',
    invoiceCode: '發票號碼',
    cartCode: '購物車編號',
    orderSn: '發票號碼​',
    actualPayment: '付款金額',
    exchangeInfo: '是次登記抽獎資料​',
    hmOnline: '港澳綫上',
    hmStore: '港澳門店',
    exchangeLotteryNum: '可兌換抽獎數',
    provideExchangeOrder: '提供兌換訂單',
    pleaseSelectOrder: '請選擇訂單',
    orderSelect: '訂單選擇',
    selectOrder: '選擇訂單',
    manualInput: '手動錄入',
    confirm: '確定',
    clauseDesc:
      '我了解此活動的詳情並同意及接受上述有關條款及細則，包括收集個人資料聲明及相關條款，並同意。',
    permissionDesc: '我不同意將我的個人資料用作服務推廣或宣傳之用途。',
    noOrderTips: '很抱歉，發票號碼​碼不能為空',
    orderFailTips: '很抱歉，該發票號碼​錯誤，請輸入正確的發票號碼​',
    lotteryNumTips: '可兌換抽獎數為0',
    checkboxTips: '請同意上述條款及細則',
    exchangeMsg: '是否確定登記抽獎資料?​',
    exchangeFailMsg: '抱歉，今日你已經參加過兌換',
    exchangeSuccess: '兌換成功',
    deadline: '來晚了，活動已結束囖！',
    notStart: '抱歉，活動未開始。',
    privacy: '條款與細則',
    OCRamount: "請按示意圖拍照，或者人工輸入消費金額",
  },
  // 会员登记
  eForm: {
    home: "會員登記",
    loginForm: {
      title: "激活莎莎貴賓卡 / 會員卡帳戶",
      account: "莎莎貴賓卡 / 會員卡號碼",
      accountPla: "請輸入卡號",
      phone: "已登記手機號碼",
      phonePla: "請輸入手機號碼",
      notes: "如忘記以上必填資料，請聯絡莎莎客戶服務熱線（852）25055023",

      accountTips1: "實體莎莎貴賓卡",
      accountTips2: "莎莎貴賓卡背面之會員號碼",
      accountTips3: "電子莎莎貴賓卡/ 會員卡",
      accountTips4: '"SaSaHK"應用程式內（按右上角之會員卡圖示），於二維碼上之貴賓卡/ 會員卡號碼',

      emptyError: "請填寫會員資料",

      E0001: "您輸入的會員號碼 / 手機號碼不正確，如需協助，請聯絡莎莎客戶服務熱線（852）25055023",
      E0002: "您的莎莎貴賓卡 / 會員卡帳戶已經確定，如需更改此帳戶資料，請聯絡莎莎客戶服務熱線（852）25055023",
      E0003: "您輸入的手機號碼已於中銀莎莎貴賓卡中使用，請輸入其他手機號碼，如6月20日前未有提交新資料，莎莎將為閣下整合及統一帳戶。如有查詢，請聯絡莎莎客戶服務熱線：（852）25055023",
      E0004: "請輸入個人手提電話號碼!",
      E0005: "您輸入的手機號碼已被登記，如需更改此帳戶資料，請聯絡莎莎客戶服務熱線（852）25055023",
      E0006: "您輸入的手機號碼已被使用，請輸入其他手機號碼，如6月20日前未有提交新資料，莎莎將為閣下整合及統一帳戶。如有查詢，請聯絡莎莎客戶服務熱線：（852）25055023",
    },
    infoForm: {
      nameCn: "會員姓名 (中文)",
      nameEn: "會員姓名 (英文)",
      mr: "先生",
      mrs: "女士",
      birthday: "生日日期",
      month: "月",
      year: "年",
      phone: "如想修改登記手機號碼，請於下列輸入新手機號碼",
      agree1: "本人同意莎莎使用提供的個人資料以用作市場直接營銷推廣之用。",
      agree2: "本人已閱讀並同意莎莎私隱政策。",
      agreelink: "https://corp.sasa.com/tc/global/privacy-policy.php",
      bocPhone: "(中銀莎莎貴賓卡會員恕不能修改)",
      successMsg: "謝謝！您的莎莎貴賓卡/ 會員卡帳戶已經確定，如需再更改資料，請聯絡莎莎客戶服務熱線（852）25055023",
      agree2Tips: "請閱讀並同意莎莎私隱政策",
    },
    btnOK: "確認",
    btnNext: "下一步",
    btnFinish: "完成",
  },
  // OMO优惠券
  omoCoupon: {
    history: "已用/過期",
    couponCode: "券Code",
    expired: "已過期",
    writtenOff: "已使用",
    all: "全部",
    tips: "提示",
    minute: "分鐘",
    notChange: "選擇後不可更改優惠券使用平台！",
    receiveSuccess: '領取成功',
    pendingRow1: "系統正在處理",
    pendingRow2: "您領取的優惠券",
    pendingRow3: "【我的優惠券】顯示相關優惠券代表成功領取；沒有顯示則代表失敗，請再按【立即領取】",
    receiveFailed: "領取失敗",
    failedtext1: "請再按立即領取",
    failedtext2: "重新領取",
    choose: "選擇",
    inputRemark: "請任選一下一種組合管道填寫生日",
    year: "年",
    month: "月",
    day: "日",
    afterReceive: "領取後",
    effectiveDays: "天有效",
  },
  noData: {
    myCoupon: "沒有優惠券可使用",
    receiveCoupon: "沒有優惠券可以領取",
    historyCoupon: "沒有歷史優惠券",
    order: "沒有訂單記錄",
    point: "沒有積分流水",
  },
  // 会员等级
  memberLevel: {
    PC: "普通會員",
    VIP: "貴賓會員",
    P2: "貴賓會員",
    P4: "貴賓會員",
  },
  // 渠道
  channel: {
    EZR: "海淘小程序",
    BOSS: "門店",
    '91APP': "網店",
  },
  // 积分类型
  pointsType: {
    getFromOrder : '已賺積分',
    payForOrder : '已用積分',
    signIn : '簽到',
    give : '贈送',
    exchange : '兌換',
    refundExchange : '兌換退單',
    refundFromOrder : '訂單退單（訂單獲得）',
    refundForOrder : '訂單退單（訂單使用）',
    Other : '其他',
  },
}
